import Header2 from "../../components/Header2/Header2";
import Bg from "../../images/shapes/shape_title_under_line.svg";
import ProcessTechnology from "../../components/software-company-components/ProcessTechnology/ProcessTechnology";
import Footer from "../../components/software-company-components/Footer/Footer";

import React, { Fragment, useState } from "react";
import resp1 from "../../images/actual/category-images/respiro/3.png";
import resp2 from "../../images/actual/category-images/respiro/respiro.png";

import s1 from "../../images/actual/category-images/respiro/s1/1.png";
import s2 from "../../images/actual/category-images/respiro/s1/9.png";
import s3 from "../../images/actual/category-images/respiro/s1/31.png";
import s4 from "../../images/actual/category-images/respiro/s1/13.png";

import he1 from "../../images/actual/category-images/respiro/departments/strip/1.png";
import he2 from "../../images/actual/category-images/respiro/departments/strip/2.png";
import he3 from "../../images/actual/category-images/respiro/departments/strip/3.png";
import he4 from "../../images/actual/category-images/respiro/departments/strip/4.png";
import he5 from "../../images/actual/category-images/respiro/departments/strip/5.png";
import he6 from "../../images/actual/category-images/respiro/departments/strip/6.png";
import he7 from "../../images/actual/category-images/respiro/departments/strip/7.png";
import he8 from "../../images/actual/category-images/respiro/departments/strip/8.png";
import he9 from "../../images/actual/category-images/respiro/departments/strip/9.png";
import he10 from "../../images/actual/category-images/respiro/departments/strip/10.png";
import he11 from "../../images/actual/category-images/respiro/departments/strip/11.png";
import he12 from "../../images/actual/category-images/respiro/departments/strip/12.png";
import he13 from "../../images/actual/category-images/respiro/departments/strip/13.png";
import he14 from "../../images/actual/category-images/respiro/departments/strip/14.png";
import he15 from "../../images/actual/category-images/respiro/departments/strip/15.png";

import b1 from "../../images/actual/category-images/respiro/departments/box/1.png";
import b2 from "../../images/actual/category-images/respiro/departments/box/2.png";
import b3 from "../../images/actual/category-images/respiro/departments/box/3.png";
import b4 from "../../images/actual/category-images/respiro/departments/box/4.png";
import b5 from "../../images/actual/category-images/respiro/departments/box/5.png";
import b6 from "../../images/actual/category-images/respiro/departments/box/6.png";
import b7 from "../../images/actual/category-images/respiro/departments/box/7.png";
import b8 from "../../images/actual/category-images/respiro/departments/box/8.png";
import b9 from "../../images/actual/category-images/respiro/departments/box/9.png";
import b10 from "../../images/actual/category-images/respiro/departments/box/10.png";
import b11 from "../../images/actual/category-images/respiro/departments/box/11.png";
import b12 from "../../images/actual/category-images/respiro/departments/box/12.png";
import b13 from "../../images/actual/category-images/respiro/departments/box/13.png";
import b14 from "../../images/actual/category-images/respiro/departments/box/14.png";
import b15 from "../../images/actual/category-images/respiro/departments/box/15.png";
import b16 from "../../images/actual/category-images/respiro/departments/box/16.png";
import b17 from "../../images/actual/category-images/respiro/departments/box/17.png";
import b18 from "../../images/actual/category-images/respiro/departments/box/18.png";
import b19 from "../../images/actual/category-images/respiro/departments/box/19.png";
import b20 from "../../images/actual/category-images/respiro/departments/box/20.png";

import bg from "../../images/backgrounds/common-banner.gif";

import About from "../../components/about/about";
import Project from "../../api/project";
import { Link } from "react-router-dom";

const Category1 = () => {
  const [activeFilter, setActiveFilter] = useState("department1");
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <Fragment>
      <div>
        <Header2 />
        <section
          className="software_company_hero_section xb-hidden"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="content_wrap">
                  <h1 style={{ display: "flex", justifyContent: "center" }}>
                    Respiro Sciences (SRS)
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="about_section section_space">
        <div className="container">
          <div className="row align-items-center justify-content-lg-center">
            <div className="col-lg-6">
              <img src={resp2} />
            </div>
            <div className="col-lg-6">
              <h2 style={{ paddingLeft: "5%" }}>
                With a range of products providing optimal treatment and
                prevention of respiratory diseases.
              </h2>
            </div>
          </div>
        </div>
      </section>

      <div style={{ paddingTop: "75px", paddingBottom: "75px" }}>
        <div className="container">
          <div className="row">
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "25px",
              }}
            >
              Saanso Respiro Sciences (SRS)
            </h1>
            <div className="col-lg-6">
              <p>
                Specializing in respiratory care, this division delivers
                inhalers and other treatments for respiratory diseases like
                asthma, chronic obstructive pulmonary disease (COPD), and
                Bronchodilators.
              </p>
              <p>
                Products include inhalers for treating respiratory disorders,
                nasal sprays, and respiratory antibiotics.
              </p>
              <br />
              <img src={resp1} alt="" />
            </div>
            <div className="col-lg-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={s1} alt="" />
              </div>

              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={s3} alt="" />
              </div>
              {/* <img src={s2} alt="" /> */}
            </div>
            {/*<div
              className="col-lg-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img src={s3} alt="" />
              <br />
              <br />
               <img src={s4} alt="" /> 
            </div>*/}
          </div>
        </div>
      </div>

      <section className="portfolio_section section_space bg-light">
        <div className="container">
          <div className="filter_elements_nav">
            <ul className="unordered_list justify-content-center">
              <li
                className={activeFilter === "department1" ? "active" : ""}
                onClick={() => handleFilterClick("department1")}
              >
                <img src={he1} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department2" ? "active" : ""}
                onClick={() => handleFilterClick("department2")}
              >
                <img src={he2} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department3" ? "active" : ""}
                onClick={() => handleFilterClick("department3")}
              >
                <img src={he3} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department4" ? "active" : ""}
                onClick={() => handleFilterClick("department4")}
              >
                <img src={he4} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department5" ? "active" : ""}
                onClick={() => handleFilterClick("department5")}
              >
                <img src={he5} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department6" ? "active" : ""}
                onClick={() => handleFilterClick("department6")}
              >
                <img src={he6} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department7" ? "active" : ""}
                onClick={() => handleFilterClick("department7")}
              >
                <img src={he7} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department8" ? "active" : ""}
                onClick={() => handleFilterClick("department8")}
              >
                <img src={he8} alt="" style={{ width: "105px" }} />
              </li>

              <li
                className={activeFilter === "department9" ? "active" : ""}
                onClick={() => handleFilterClick("department9")}
              >
                <img src={he9} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department10" ? "active" : ""}
                onClick={() => handleFilterClick("department10")}
              >
                <img src={he10} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department11" ? "active" : ""}
                onClick={() => handleFilterClick("department11")}
              >
                <img src={he11} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department12" ? "active" : ""}
                onClick={() => handleFilterClick("department12")}
              >
                <img src={he12} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department13" ? "active" : ""}
                onClick={() => handleFilterClick("department13")}
              >
                <img src={he13} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department14" ? "active" : ""}
                onClick={() => handleFilterClick("department14")}
              >
                <img src={he14} alt="" style={{ width: "105px" }} />
              </li>
              <li
                className={activeFilter === "department15" ? "active" : ""}
                onClick={() => handleFilterClick("department15")}
              >
                <img src={he15} alt="" style={{ width: "105px" }} />
              </li>
            </ul>
          </div>

          <div className="container">
            {activeFilter === "department1" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b1} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Fluticasone Furoate 27.5mcg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department2" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b2} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Azelastine Hcl 140mcg + Fluticasone Propionate 50 mcg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department3" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b3} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Saline Nasal Spray 0.65% w/v 20ml
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department4" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b4} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Oxymetazoline Hcl Nasal Spray 0.05%
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department5" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b5} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Montelukast 10mg + Fexofenadine Hcl 120mg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department6" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b6} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Montelukast 10mg + Levocetrizine 5mg + Ambroxol Hcl SR 75mg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department7" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b7} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Acebrophylline 100mg + Acetylcysteine 600mg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department8" ? (
              <div className="row">
                <div className="col-lg-6">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b8} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Levofloxacin 500 mg
                  </h6>
                </div>
                <div className="col-lg-6">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b9} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Levofloxacin 750 mg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department9" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b10} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Azithromycin 500 mg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department10" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b11} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Amoxicillin 500mg + Potassium Clavulanate 125mg + Lactic
                    Acid Bacillus 60 million Spores
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department11" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b14} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Clarithromycin 500mg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department12" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b13} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Moxifloxacin Hcl 400 mg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department13" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b12} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Cefpodoxime 200mg + Potassium Clavulanate 125mg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department14" ? (
              <div className="row">
                <div className="col-lg-6">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b15} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Budesonide 200 mcg + Formoterol Fumarate 6 mcg
                  </h6>
                </div>
                <div className="col-lg-6">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b16} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Budesonide 400 mcg + Formoterol Fumarate 6 mcg
                  </h6>
                </div>
                <div className="col-lg-6">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b17} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Budesonide 400 mcg + Formoterol Fumarate 12 mcg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
            {activeFilter === "department15" ? (
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={b18} alt="" />{" "}
                  </div>
                  <h6 style={{ display: "flex", justifyContent: "center" }}>
                    Formoterol Fumarate 6 mcg + Fluticasone propionate 250 mcg
                  </h6>
                </div>
              </div>
            ) : (
              " "
            )}
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};
export default Category1;
